import { useModal } from "@xcorejs/ui";
import RobeLightbox, { RobeLightboxProps } from "design-system/robe/RobeLightbox";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useSection = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    window.innerWidth >= 1024 && setActive(true);
  }, []);

  return [active, () => setActive(!active), setActive] as [boolean, () => unknown, Dispatch<SetStateAction<boolean>>];
};

export const useGallery = (props: Omit<RobeLightboxProps, "index">): [(index: number) => unknown] => {
  const [open] = useModal(RobeLightbox, props);

  return [i => open({ index: i })];
};

export const datasheetUrl = (id: number, locale: string) =>
  `/api/robe/pdfs/${locale}/product/${id}`;
